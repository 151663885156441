.FamousPoetry-main {
  background-image: url(../../assets/h5-bckg-img-02.jpg);
  background-size: cover;
  height: fit-content;
  width: 100%;
}
.FamousPoetry-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 0px;
  margin: 20px 0px;
}
.FamousPoetry-card-img {
  width: 150px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.FamousPoetry-card-text {
  font-size: 18px;
  padding-top: 10px;
  font-weight: 600;
}
.FamousPoetry-card:hover {
  background-color: #000;
  color: #fff;
}
.FamousPoetry-heading {
  padding: 60px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  gap: 10px;
  font-weight: 700;
}
.FamousPoetry-contanier {
  padding-bottom: 100px;
}
