.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.VidoePoetry-item {
  background-color: black;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}
.VidoePoetry-item .poertryimg-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}
.VidoePoetry-item-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset !important;
  box-shadow: none !important;
  cursor: pointer;
}

.playicon-img {
  width: 50px !important;
  height: 50px !important;
}
.VidoePoetry-item .poertryimg-img::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -1;
  border-radius: 10px;
}
.VidoePoetry-item-text {
  text-align: left;
  padding: 0px 15px 15px;
  color: #ffffff8f;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.swiper-button-prev {
  display: unset !important;
}
.swiper-button-next {
  display: unset !important;
}
.VidoePoetry-contanier {
  margin: 80px 0px;
}
.VidoePoetry-contanier .FamousPoetry-heading {
  justify-content: flex-start;
}
