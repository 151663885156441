.Sidebar-container {
  background-color: #000;
  height: 100vh;
}
.Sidebar-nav-list {
  display: flex;
  padding: 20px 20px;
  gap: 40px;
  list-style-type: none;
  flex-direction: column;
}
.Sidebar-list {
  font-size: 18px !important;
  line-height: normal;
  font-weight: 600;
  color: #fff !important;
  background-color: unset !important;
  border: unset !important;
  padding: 0px !important;
}
.Sidebar-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.Sidebar-icon img {
  width: 20px !important;
  height: 20px !important;
}
.sub-contain {
  display: flex;
  gap: 10px;
  flex-direction: column;
  font-size: 16px !important;
  line-height: normal;
  font-weight: 500;
  color: #fff !important;
  background-color: unset !important;
  border: unset !important;
}
.Side-dropdown .Sidebar-icon {
  padding-bottom: 15px;
}
.Side-dropdown .Sidebar-icon:last-child {
  padding-bottom: 0px !important;
}
.Side-dropdown {
  margin: 15px 0px 0px 20px !important;
}
.side-dropdown-arrow {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  right: 0;
}
.Side-open {
  position: relative;
}
