/* .footer-warrp {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
} */
.Footer-logo-container img {
  width: 180px;
  height: auto;
  padding: 0px 0px 10px 0px;
}
.Footer-main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.Footer-links-container ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Footer-links-container ul li a {
  text-decoration: none;
  transition: color 0.3s ease;
  background-color: #000;
  width: 29px;
  display: flex;
  height: 30px;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
}
.Footer-links-container ul li a:hover {
  background-color: red;
}
.Footer-main-container {
  padding: 100px 0px 80px;
}
.Footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Footer-header {
  font-size: 22px;
  line-height: 30px;
  width: 70%;
  text-align: center;
  color: #000000a8;
  margin-bottom: 30px;
}
.footer-bottom {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  padding: 20px 0px;
  background-color: #fff;
  box-shadow: 5px 0 0 rgba(0, 0, 0, 0.3) !important;
  font-style: italic;
}
