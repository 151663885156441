.VideoPage-main-sec {
  background-color: #ffc10712;
  padding: 50px 0;
}
.VideoPage-title {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 36px;
}
.VideoPage-list h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin: 20px 0px;
}
.VideoPage-list-sec {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
