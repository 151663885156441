.AboutUs-img img {
  width: 100%;
  margin-top: 30px;
}
.AboutUs-main {
  background-image: url(../../assets/h5-bckg-img-02.jpg);
  background-size: cover;
  height: fit-content;
  width: 100%;
}
.AboutUs-heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.AboutUs-heading h1 {
  font-size: 30px;
  padding: 20px 0px;
  color: #000;
  text-align: left;
  font-weight: 700;
  line-height: 1.5;
}
.about-text-container .FamousPoetry-heading {
  justify-content: flex-start;
}
.about-text-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.about-text-container .Footer-links-container ul {
  justify-content: flex-end;
}
.AboutUs-contanier {
  padding: 50px 0px 70px;
}
.AboutUs-text p {
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.5;
}
