.ContactFrom-Sec {
  padding: 0px 50px;
}
.contact-warp .form-group {
  padding-bottom: 20px;
}
.btn-submit {
  margin-top: 20px;
  background-color: #000;
  color: white;
  border: none;
  width: fit-content !important;
  padding: 10px 30px;
  border-radius: 8px;
  font-size: 18px;
}
.btn-submit:hover {
  background-color: red;
}
.form-group-btn {
  display: flex;
  justify-content: flex-end;
}
