.poetry-image .Poetrys-card-img {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}
.Poetrys-card {
  position: relative;
}
.Poetrys-card-body {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgb(52 50 50 / 76%);
  padding: 30px;
  color: white;
  border-radius: 10px;
}
.poetry-image {
  position: relative;
}
.Poetrys-contanier {
  padding-bottom: 120px;
}
.Poetrys-body-text {
  display: flex;
  flex-direction: column;
}
.Poetrys-body-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.poetry-Qute {
  display: flex;
  justify-content: left;
}
.poetry-Qute .Poetrys-card-img {
  width: 50px;
}
.poetry-Qute-ratata {
  display: flex;
  justify-content: flex-end;
}
.poetry-Qute-ratata .Poetrys-card-img {
  transform: rotate(180deg);
}
.Poetrys-card-title a {
  font-size: 22px;
  line-height: 34px;
  color: #fff;
  text-decoration: none;
  font-style: italic;
}
.Poetrys-card-title a:hover {
  color: rgba(255, 0, 0, 0.744);
}
.Poetrys-card-title {
  margin: 20px 0px 10px 0px;
}
