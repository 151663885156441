.Blog-background {
  background-image: url(../../assets/blog-background.jpg);
  background-size: cover;
  background-position: 100% 100%;
  height: 500px;
  width: 100%;
}
.Blogdetail-background {
  background-image: url(../../assets/Blog-blog.avif);
  background-size: cover;
  background-position: 100% 100%;
  height: 500px;
  width: 100%;
}
.Blog-main {
  padding: 80px 0px 80px 0px;
}
.Blog-heading {
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}
.Blog-Search input {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid #000;
  width: 100%;
  /* position: relative; */
}
.Blog-Search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Search-container {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin-bottom: 30px;
}

.Blog-Search .Search-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 30px;
  background-color: #a3a39842;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
}
.Blog-btn-catager {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blog-btn-catager ul {
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.Blog-btn-catager ul li a {
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  padding: 10px 30px 10px;
  border-radius: 50px;
}
.Blog-btn-catager ul li .active {
  background-color: red;
}
.Blog-btn-catager ul li a:hover {
  background-color: red;
}
.Blog-show-contain .Blogs-main-content {
  padding: 0;
}
.Blog-show-contain .Blogs-main-content {
  display: none;
}
.Blog-show-contain .Featureblog-main {
  background-image: none;
}
.Blog-show-contain .Blogs-contanier {
  padding-bottom: 0;
}
.Blog-pagination ul {
  list-style: none;
  padding: 0;
  margin: 50px 0px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.Blog-pagination ul li a {
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  background-color: #000;
  border-radius: 10px;
  height: 40px !important;
  width: 40px;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  color: #fff;
  align-items: center;
  height: 100%;
}
.Blog-pagination ul li a:hover {
  background-color: red;
}

.Blog-pagination ul li .arrow {
  background-color: red;
}
.Blog-pagination .active {
  background-color: red;
}
.Blog-pagination .arrow img {
  width: 30px;
}
.Blogdetail-img img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.Blogdetail {
  margin: 80px 0px 100px;
}
.Blogdetail-Instagram-item img {
  width: 100%;
  height: auto;
}
.Blogdetail-Instagram-img img {
  width: 100%;
  height: auto;
}
.Blogdetail-text-second img {
  width: 100%;
  height: 300px;
  object-fit: inherit;
  margin-top: 20px;
}
.Blogdetail-content {
  margin-top: 50px !important;
}
.Blogdetail-post .Featureblog-card-body {
  display: flex;
  flex-direction: column;
  height: auto !important;
  width: 100%;
  justify-content: left;
  align-items: first baseline;
}
.Blogdetail-post .Featureblog-body-text {
  width: 100% !important;
  max-width: 100% !important;
}

.Blogdetail-post .Featureblog-card-text {
  width: 100% !important;
  max-width: 100% !important;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #878787;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 30px 0px !important;
}
.Blogdetail-post .Featureblog-card-title a:hover {
  color: unset !important;
  text-shadow: none !important;
}
.Blogdetail-Categories {
  margin: 20px 0px;
}
.Blogdetail-Categories h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #000;
}

.Blogdetail-Categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: left;
}
.Blogdetail-Categories ul a {
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}
.Blogdetail-Instagram-main h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #000;
}
.Blogdetail-newsletter {
  margin: 20px 0px;
}
.Blogdetail-newsletter h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #000;
}
.Blogdetail-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: red;
  border: unset;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 500;
  color: #fff;
}
.comments-list .form-group textarea {
  height: 150px;
}
.comments-list .form-group input {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
}
.comments-list .form-check {
  display: flex;
  align-items: top;
  gap: 5px;
}
.comments-list .form-check input {
  width: 20px !important;
  height: 20px !important;
  padding: 0px !important;
}
