.contact-background {
  background-image: url(../../assets/background-contact.avif);
  background-size: cover;
  background-position: 100% 100%;
  height: 500px;
  width: 100%;
}
.Contact-image-side img {
  width: 100%;
  height: 600px;
  border-radius: 20px;
}
.contact-form-main {
  padding: 100px 0px;
}
.Contact-from-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
