.poetry-background {
  background-image: url(../../assets/background2.jpg);
  background-size: cover;
  background-position: 100% 100%;
  height: 500px;
  width: 100%;
}
.poetrydetail-background {
  background-color: antiquewhite;
  background-size: cover;
  background-position: 100% 100%;
  width: 100%;
}

.poetrydetail-img img {
  width: 100% !important;
  height: 500px !important;
}
.poetrydetail .Blogdetail-post .Featureblog-card-text {
  font-style: italic;
}
.poetrydetail .Featureblog-main {
  background-image: none !important;
  margin-top: 50px;
}
.poetrydetail .Blogs-main-content {
  padding: 20px 0px 20px 0px !important;
}
.poetrydetail .Blogs-main-content {
  display: none;
}
.poetrydetail .Poetrys-contanier {
  padding-bottom: 50px;
}
.Blogdetail-post-comment h4 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.poetrydetail {
  margin: 50px 0px 100px 0px;
}

/* hbery5o

*/

/* src/PoetryDetailPage.css */

.poetry-detail-page {
  background-color: #ffc10712;
  padding: 50px 0px;
  height: 100%;
}
.poetry-body-background {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.poetry-body {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.image-center-logo {
  width: auto;
  height: auto;
}
.poetry-image-main {
  display: flex;
  justify-content: center;
}
.poetry-header h1 {
  font-size: 2.5em;
  margin-bottom: 0;
}

.poetry-header p {
  margin: 5px 0;
  font-size: 1.1em;
}
.poetry-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.poetry-image-theme {
  width: 100%;
  height: 300px;
  object-fit: fill;
  margin: 0px 0px 30px 0px;
}
.poetry-description {
  font-style: italic;
  margin-bottom: 20px;
}

.poetry-text pre {
  white-space: pre-wrap;
  font-size: 1.2em;

  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
}

.poetry-video {
  margin-top: 30px;
}

.poetry-video h3 {
  margin-bottom: 10px;
}
.poetry-video-Related {
  display: flex;
  justify-content: left;
  gap: 20px;
}
.poetry-img-side .sideimg-img {
  width: 100%;
  height: auto;
}
.poetry-cagater-list {
  display: flex;
  justify-content: left;
  gap: 20px;
}
.poetry-cagater-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}
.poetry-cagater-list li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  transition: color 0.3s ease;
  &:hover {
    color: #ffc107;
  }
}
.poetry-heading-side {
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  color: #000;
}
