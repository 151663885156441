.main-header-poetry {
  /* position: fixed;
  width: 100%;
  top: 0;
  left: 0; */
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-logo img {
  width: 180px;
  height: auto;
  padding: 0px 0px 10px 0px;
}

.nav-container .nav-links {
  display: flex;
  align-items: center;
}
.nav-list {
  display: flex;
  align-items: center;
  gap: 30px;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}
.nav-container .nav-link {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  text-decoration: none;
  padding-bottom: 5px;
}
.nav-container .nav-link:hover {
  color: rgba(255, 0, 0, 0.711);
}
.nav-btn {
  border: 2px solid rgba(255, 0, 0, 0.711);
  background-color: #000;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  margin: 0px;
  font-size: 18px;
  line-height: normal;
}
.nav-container .nav-link.active {
  color: rgba(255, 0, 0, 0.711);
  font-weight: 700;
}
