.home-background {
  background-image: url(../../assets/background3.jpeg);
  background-size: cover;
  /* background-position: 100% 100%; */
  height: 500px;
  width: 100%;
}
.home-poetry h2 {
  font-size: 40px;
  font-weight: 800;
  color: white;
  text-align: center;
  width: 50%;
  margin-bottom: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 1px 1px 2px red, 0 0 1em black, 0 0 0.2em black;
}
.home-poetry {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}
@media screen and (max-width: 1024px) {
  .home-poetry h2 {
    font-size: 30px !important;
    width: 80% !important;
  }
  .Featureblog-body-text {
    width: 90% !important;
  }
  .Blog-show-contain .Featureblog-date-description span {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: normal;
  }
  .Blog-background {
    background-image: url(http://localhost:3000/static/media/blog-background.bba5809….jpg);
    background-size: cover;
    background-position: 100% 100%;
    height: 380px !important;
    width: 100%;
  }
  .contact-background {
    height: 380px !important;
    width: 100%;
  }
  .poetry-background {
    height: 380px !important;
    width: 100%;
  }
  .poetrydetail-background {
    height: 380px !important;
    width: 100%;
  }
  .Blogdetail-background {
    height: 380px !important;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .main-fathe {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .Featureblog-body-text {
    width: 100% !important;
  }
  .mainto .Featureblog-body-text {
    margin-top: 20px !important;
  }
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding-bottom: 20px !important;
    flex-direction: column !important;
  }
  .Featureblog-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px !important;
    font-size: 30px !important;
    gap: 10px;
    font-weight: 700;
  }
  .FamousPoetry-heading {
    padding: 60px 0px 20px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px !important;
    gap: 10px !important;
    font-weight: 700 !important;
  }
  .Blogs-main-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 30px 0px !important;
  }
  .FamousPoetry-contanier {
    padding-bottom: 30px !important;
  }
}
@media screen and (max-width: 567px) {
  .Search-container {
    position: relative;
    width: 100% !important;
    padding: 0px 10px !important;
    max-width: 100% !important;
    margin-bottom: 30px !important;
  }
  .Blog-Search .Search-btn {
    position: absolute;
    top: 0;
    right: 10px !important;
    padding: 10px 28px;
    background-color: #a3a39842;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
  }
  .image-center-logo {
    width: 100% !important;
  }
  .poetry-header h1 {
    font-size: 26px !important;
    margin-bottom: 10px !important;
  }
  .poetrydetail-background {
    height: 250px !important;
    width: 100%;
  }
  .home-background {
    height: 300px !important;
    width: 100%;
  }
  .nav-list {
    gap: 10px !important;
  }
  .nav-container .nav-link {
    font-size: 14px !important;
  }
  .nav-btn {
    border: 2px solid rgba(255, 0, 0, 0.711);
    background-color: #000;
    padding: 4px 16px 7px !important;
    border-radius: 10px !important;
    color: #fff;
    text-decoration: none;
    margin: 0px;
    font-size: 14px !important;
    line-height: normal;
  }
  .Featureblog-contanier {
    padding-bottom: 0px !important;
  }
  .FamousPoetry-heading {
    padding: 60px 0px 20px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    gap: 10px !important;
    font-weight: 700 !important;
  }
  .Featureblog-heading-main-b {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 30px !important;
    font-size: 26px !important;
    gap: 10px;
    font-weight: 700;
    margin: 0px;
  }
  .Featureblog-heading-posts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px !important;
    font-size: 22px !important;
    gap: 10px;
    font-weight: 700;
  }
  .Newsletter-heading {
    font-size: 28px !important;
    font-weight: 800;
    line-height: 50px;
  }
  .Newsletter-subheading {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
    color: #000;
  }
  .Blogs-see-all {
    font-size: 12px !important;
    font-weight: 500;
    border: unset;
    background-color: #000;
    padding: 7px 28px !important;
    color: #fff;
    border-radius: 20px;
  }
  .Footer-header {
    font-size: 18px !important;
    line-height: 24px;
    width: 80% !important;
    text-align: center;
    color: #000000a8;
  }
  .Blog-background {
    height: 250px !important;
  }
  .Blog-main {
    padding: 50px 0px 50px 0px !important;
  }
  .Blog-btn-catager ul {
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap !important;
  }
  /* .AboutUs-contanier {
    padding: 50px 0px 50px !important;
  } */
  .Blog-btn-catager ul li a {
    font-size: 12px !important;
    line-height: normal;
    font-weight: 500;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    padding: 6px 16px 6px !important;
    border-radius: 50px;
  }
  .Featureblog-date-description span {
    font-size: 12px !important;
    font-weight: 600;
    line-height: normal;
  }
  .VidoePoetry-contanier {
    margin: 0px 0px 30px !important;
  }
  .Contact-image-side img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
  }
  .contact-background {
    height: 250px !important;
    width: 100%;
  }
  .poetry-background {
    height: 250px !important;
    width: 100%;
  }
  .ContactFrom-Sec {
    padding: 30px 0px !important;
  }
  .Blogdetail-background {
    height: 250px !important;
    width: 100%;
  }
  .Blogdetail-post .Featureblog-date-description span {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
  }
  .comments-list {
    margin-bottom: 30px;
  }
}
