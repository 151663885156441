.Userlist-heading {
  background-color: #000;
  padding: 10px 10px;
  margin: 0;
}
.Userlist-heading h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}
.Admin-blog-img {
  width: 60px !important;
  height: 30px !important;
  object-fit: cover;
}
