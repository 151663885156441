.From-upload {
  display: flex;
  flex-direction: column;
}
.upload-form-container {
  padding: 30px 0px;
}
.From-upload label {
  font-size: 20px;
  margin-bottom: 5px;
  color: #000;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}
.From-upload input {
  padding: 6px;
  border: 1px solid #00000069;
  border-radius: 5px;
}
.From-upload select {
  padding: 6px;
  border: 1px solid #00000069;
  border-radius: 5px;
}
.From-upload textarea {
  padding: 6px;
  border: 1px solid #00000069;
  border-radius: 5px;
}
.btn-group-upload {
  display: flex;
  align-items: center;
  justify-content: right;
}
.btn-group-upload button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: white;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;

  &:hover {
    background-color: red;
    color: #fff;
  }
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
