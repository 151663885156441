.main-admin-box {
  margin: 50px 0px;
}
.LInk-side-date {
  background-color: #e9eceffc;
  height: 100vh;
  /* border: 1px solid #00000024; */
}
.mian-sec-date img {
  width: 200px !important;
  height: 200px !important;
  object-fit: contain !important;
}
.log-imaga-admin {
  width: 150px !important;
  height: 150px !important;
  object-fit: contain !important;
}
.mian-sec-date {
  width: 100%;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Admin-heading {
  width: 100%;
  text-align: center;
  background-color: #212121d6;
}
.Admin-heading h2 {
  font-size: 30px;
  color: #fff;
  padding: 10px 0px;
}
/* .admin-top-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
} */
