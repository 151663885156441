.Newsletter-contanier {
  padding: 130px 0px;
}
.Newsletter-card-img {
  width: 100%;
}
.Newsletter-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.Newsletter-heading {
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
}
.Newsletter-subheading {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
}
.Newsletter-form {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.Newsletter-form input {
  border: unset;
  padding: 10px 20px;
  border-radius: 4px;
}
.Newsletter-form input:focus-visible {
  border: unset;
  box-shadow: unset;
  outline: unset;
}
.Newsletter-btn {
  background-color: #000;
  color: #fff;
  border: unset;
  padding: 9px 26px;
  font-size: 16px;
  border-radius: 6px;
}
.Newsletter-btn:hover {
  background-color: red;
}
