.Featureblog-main {
  background-image: url(../../assets/h5-bckg-img-01.jpg);
  background-size: cover;
  background-position: center;
  height: fit-content;
  width: 100%;
}
.Featureblog-card .Featureblog-card-img {
  width: 100%;
  border-radius: 12px;
  height: 350px;
  object-fit: cover;
}
.Feature-img {
  background-image: url(../../assets/h5-bckg-img-02.jpg);
  background-size: cover;
  background-position: center;
  height: fit-content;
  width: fit-content;
  padding: 5px 10px 10px;
  color: red;
}
.Featureblog-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0px;
  font-size: 40px;
  gap: 10px;
  font-weight: 700;
}
.Featureblog-contanier {
  padding-bottom: 80px;
}
.Featureblog-card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 70%; */
  justify-content: center;
  align-items: center;
}
.Featureblog-body-text {
  width: 70%;
}
.Featureblog-date {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 10px;
}
.Featureblog-date-description {
  display: flex;
  gap: 5px;
  align-items: center;
}
.Featureblog-date-description span {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.Featureblog-card-title a {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  color: #000;
  font-family: system-ui;
  text-decoration: none;
}
.Featureblog-card-text {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-family: system-ui;
  margin-bottom: 10px;
  max-width: 500px;
}
.text-button {
  font-size: 14px;
  font-weight: 500;
  background-color: unset;
  border: unset;
  padding: 0;
}
.Featureblog-card-title a:hover {
  text-shadow: 1px 1px 2px red, 0 0 1em rgba(0, 0, 0, 0.125),
    0 0 0.2em rgba(0, 0, 0, 0.124);
}
