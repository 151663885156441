.LoginFrom-Sec {
  background-color: #000 !important;
  width: 100% !important;
  padding: 30px 30px 50px 30px !important;
  margin: auto;
  position: relative;
}
.LoginFrom-warp h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.LoginFrom-warp .form-group label {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0px;
}

.LoginFrom-btn button {
  background-color: red;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 20px;
  border: none;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
}
.LoginFrom-btn button:hover {
  background-color: #fff !important;
  color: #000 !important;
}
.LoginFrom-icon ul li a {
  color: #fff;
  font-size: 24px;
}
.LoginFrom-icon ul {
  list-style: none;
  padding: 0px;
  margin: 10px 0px;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.LoginFrom-icon ul img {
  width: 20px !important;
  height: 20px !important;
}
.btn-signup {
  background-color: #fff !important;
  color: #000;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 10px 20px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}
.btn-signup:hover {
  background-color: red !important;
}
.modal-content {
  background-color: unset !important;
  border: unset !important;
}
.LoginFrom-close {
  background-color: unset !important;
  border: unset !important;
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer !important;
  color: #fff !important;
  padding: 0px !important;
  margin: 0px;
}
.LoginFrom-close img {
  width: 40px !important;
  height: 40px !important;
}
