.Blogs-card {
  border: 1px solid #00000024;
  padding: 20px;
}
.Blogs-card-img {
  width: 100%;
}
.Blogs-card .Featureblog-card-body {
  align-items: start;
  padding-top: 20px;
}
.Blogs-main-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0px 20px 0px;
}
.Blogs-main-content .Featureblog-heading {
  padding: 0px;
}
.Blogs-contanier {
  padding-bottom: 80px;
}
.Blogs-see-all {
  font-size: 16px;
  font-weight: 500;
  border: unset;
  background-color: #000;
  padding: 7px 30px;
  color: #fff;
  border-radius: 20px;
}
.Blogs-see-all:hover {
  background-color: red;
}
.Blogs-card .Featureblog-body-text {
  width: 100%;
}
.blog-catagorter-itam {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: fit-content;
  padding: 7px 18px;
  border-radius: 30px;
  gap: 3px;
  position: absolute;
  top: 20px;
  left: 15px;
}
.blog-catagorter-itam:hover {
  background-color: red;
}
.Blog-catagorter {
  position: relative;
}
.blog-catagorter-itam span {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
